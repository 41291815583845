import { useCallback } from 'react';
import getConfig from 'next/config';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { LanguageName } from '../graphql/generated';
import { sortLanguages } from './language.helpers';

export const useDomainLanguage = () => {
  const { publicRuntimeConfig = {} } = getConfig() || {};

  return useCallback(
    (domain: string, lang?: LanguageName) => {
      const domainLocaleMap = publicRuntimeConfig.DYNAMIC_I18N_CONFIG
        ?.domainLocaleMap;

      const sortedDomainLocaleMap = sortLanguages(domainLocaleMap);

      const domainLanguages = sortedDomainLocaleMap?.[domain];

      const matchedLanguage = lang ?? domainLanguages
        ?.find((locale: string) => locale === lang);

      if (
        matchedLanguage
        && domainLanguages?.includes(matchedLanguage)
      ) {
        return matchedLanguage;
      }

      return domainLanguages?.[0] || DEFAULT_LOCALE;
    },
    [publicRuntimeConfig],
  );
};
