import { useMemo } from 'react';
import { Language } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  LanguagesQueryResult,
  useLanguagesQuery,
} from '@/controllers/language/graphql/generated/languages.query.generated';

interface UseLanguages {
  (domain?: string): [Language[], LanguagesQueryResult];
}

export const useLanguages: UseLanguages = (domain) => {
  const { subDomain } = useSubDomainContext();

  const languagesQuery = useLanguagesQuery({
    variables: {
      domain: domain || subDomain,
    },
  });

  const languages = useMemo(
    () => (languagesQuery.data
      ? languagesQuery.data.languages as Language[]
      : []),
    [languagesQuery.data],
  );

  return [languages, languagesQuery];
};
