import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconFlagBR: FCIcon = (props) => (
  <BaseIcon
    width="720"
    height="504"
    viewBox="-1575 -1102 3150 2205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <path id="D" fillRule="evenodd" d="m-31.5 0h33a30 30 0 0 0 30 -30v-10a30 30 0 0 0 -30 -30h-33zm13-13h19a19 19 0 0 0 19 -19v-6a19 19 0 0 0 -19 -19h-19z" />
      <path id="E" transform="translate(-31.5)" d="m0 0h63v-13h-51v-18h40v-12h-40v-14h48v-13h-60z" />
      <path id="e" d="m-26.25 0h52.5v-12h-40.5v-16h33v-12h-33v-11h39.25v-12h-51.25z" />
      <g id="G">
        <clipPath id="gcut">
          <path d="m-31.5 0v-70h63v70zm31.5-47v12h31.5v-12z" />
        </clipPath>
        <use xlinkHref="#O" clipPath="url(#gcut)" />
        <rect y="-35" x="5" height="10" width="26.5" />
        <rect y="-35" x="21.5" height="35" width="10" />
      </g>
      <path id="M" d="m-31.5 0h12v-48l14 48h11l14-48v48h12v-70h-17.5l-14 48-14-48h-17.5z" />
      <path id="O" fillRule="evenodd" d="m0 0a31.5 35 0 0 0 0 -70 31.5 35 0 0 0 0 70m0-13a18.5 22 0 0 0 0 -44 18.5 22 0 0 0 0 44" />
      <path id="P" fillRule="evenodd" d="m-31.5 0h13v-26h28a22 22 0 0 0 0 -44h-40zm13-39h27a9 9 0 0 0 0 -18h-27z" />
      <g id="R">
        <use xlinkHref="#P" />
        <path d="m28 0c0-10 0-32-15-32h-19c22 0 22 22 22 32" />
      </g>
      <path id="S" d="m-15.75-22c0 7 6.75 10.5 16.75 10.5s14.74-3.25 14.75-7.75c0-14.25-46.75-5.25-46.5-30.25 0.25-21.5 24.75-20.5 33.75-20.5s26 4 25.75 21.25h-15.25c0-7.5-7-10.25-15-10.25-7.75 0-13.25 1.25-13.25 8.5-0.25 11.75 46.25 4 46.25 28.75 0 18.25-18 21.75-31.5 21.75-11.5 0-31.55-4.5-31.5-22z" />
      <g id="star" fill="#fff">
        <g id="c">
          <path id="t" transform="rotate(18 0,-1)" d="m0-1v1h0.5" />
          <use xlinkHref="#t" transform="scale(-1,1)" />
        </g>
        <use xlinkHref="#c" transform="rotate(72)" />
        <use xlinkHref="#c" transform="rotate(-72)" />
        <use xlinkHref="#c" transform="rotate(144)" />
        <use xlinkHref="#c" transform="rotate(216)" />
      </g>
      <g id="star1">
        <use xlinkHref="#star" transform="scale(31.5)" />
      </g>
      <g id="star2">
        <use xlinkHref="#star" transform="scale(26.25)" />
      </g>
      <g id="star3">
        <use xlinkHref="#star" transform="scale(21)" />
      </g>
      <g id="star4">
        <use xlinkHref="#star" transform="scale(15)" />
      </g>
      <g id="star5">
        <use xlinkHref="#star" transform="scale(10.5)" />
      </g>
    </defs>
    <rect y="-100%" x="-50%" height="200%" fill="#009440" width="100%" />
    <path d="m-1743 0 1743 1113 1743-1113-1743-1113z" fill="#ffcb00" />
    <circle r="735" fill="#302681" />
    <clipPath id="band">
      <circle r="735" />
    </clipPath>
    <path fill="#fff" d="m-2205 1470a1785 1785 0 0 1 3570 0h-105a1680 1680 0 1 0 -3360 0z" clipPath="url(#band)" />
    <g transform="translate(-420,1470)" fill="#009440">
      <use y="-1697.5" xlinkHref="#O" transform="rotate(-7)" />
      <use y="-1697.5" xlinkHref="#R" transform="rotate(-4)" />
      <use y="-1697.5" xlinkHref="#D" transform="rotate(-1)" />
      <use y="-1697.5" xlinkHref="#E" transform="rotate(2)" />
      <use y="-1697.5" xlinkHref="#M" transform="rotate(5)" />
      <use y="-1697.5" xlinkHref="#e" transform="rotate(9.75)" />
      <use y="-1697.5" xlinkHref="#P" transform="rotate(14.5)" />
      <use y="-1697.5" xlinkHref="#R" transform="rotate(17.5)" />
      <use y="-1697.5" xlinkHref="#O" transform="rotate(20.5)" />
      <use y="-1697.5" xlinkHref="#G" transform="rotate(23.5)" />
      <use y="-1697.5" xlinkHref="#R" transform="rotate(26.5)" />
      <use y="-1697.5" xlinkHref="#E" transform="rotate(29.5)" />
      <use y="-1697.5" xlinkHref="#S" transform="rotate(32.5)" />
      <use y="-1697.5" xlinkHref="#S" transform="rotate(35.5)" />
      <use y="-1697.5" xlinkHref="#O" transform="rotate(38.5)" />
    </g>
    <use id="αCMi" y="-132" x="-600" xlinkHref="#star1" />
    <use id="αCMa" y="177" x="-535" xlinkHref="#star1" />
    <use id="βCMa" y="243" x="-625" xlinkHref="#star2" />
    <use id="γCMa" y="132" x="-463" xlinkHref="#star4" />
    <use id="δCMa" y="250" x="-382" xlinkHref="#star2" />
    <use id="εCMa" y="323" x="-404" xlinkHref="#star3" />
    <use id="αVir" y="-228" x="228" xlinkHref="#star1" />
    <use id="αSco" y="258" x="515" xlinkHref="#star1" />
    <use id="βSco" y="265" x="617" xlinkHref="#star3" />
    <use id="εSco" y="323" x="545" xlinkHref="#star2" />
    <use id="θSco" y="477" x="368" xlinkHref="#star2" />
    <use id="ιSco" y="551" x="367" xlinkHref="#star3" />
    <use id="κSco" y="419" x="441" xlinkHref="#star3" />
    <use id="λSco" y="382" x="500" xlinkHref="#star2" />
    <use id="μSco" y="405" x="365" xlinkHref="#star3" />
    <use id="αHya" y="30" x="-280" xlinkHref="#star2" />
    <use id="γHya" y="-37" x="200" xlinkHref="#star3" />
    <use id="αCru" y="330" xlinkHref="#star1" />
    <use id="βCru" y="184" x="85" xlinkHref="#star2" />
    <use id="γCru" y="118" xlinkHref="#star2" />
    <use id="δCru" y="184" x="-74" xlinkHref="#star3" />
    <use id="εCru" y="235" x="-37" xlinkHref="#star4" />
    <use id="αTrA" y="495" x="220" xlinkHref="#star2" />
    <use id="βTrA" y="430" x="283" xlinkHref="#star3" />
    <use id="γTrA" y="412" x="162" xlinkHref="#star3" />
    <use id="αCar" y="390" x="-295" xlinkHref="#star1" />
    <use id="σOct" y="575" xlinkHref="#star5" />
  </BaseIcon>
);
