import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { changeLanguage } from '@/middleware/i18n';
import { LanguageName } from '@/controllers/graphql/generated';
import { CountrySelectorForm } from '@/components/landing/CountrySelectorModal/CountrySelectorForm';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import {
  DEFAULT_SUB_DOMAIN,
} from '@/middleware/i18n/i18n.config';
import { useDomainLanguage } from '@/controllers/language/useDomainLanguage';
import { analyticsSDK } from '@/controllers/analytics';
import { useAuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { useUpdateUserDomainAndLanguageMutation } from '@/controllers/user/graphql/generated/updateUserDomainAndLanguage.mutation.generated';

interface Props {
  closeModal?: () => void;
}

interface FormData {
  location?: SelectOptionInterface<string>;
  language?: LanguageName;
  timezone?: SelectOptionInterface<string>;
}

export const CountrySelector: FC<Props> = ({ closeModal }) => {
  const { data } = useAuthUserQuery({
    ssr: false,
  });

  const getDomainLanguage = useDomainLanguage();

  const [updateDomainAndLanguage] = useUpdateUserDomainAndLanguageMutation();

  const handleSubmit = useCallback(async (formData: FormData) => {
    const domain = formData.location?.value || DEFAULT_SUB_DOMAIN;

    const language = getDomainLanguage(domain, formData.language);

    if (data?.authUser) {
      await updateDomainAndLanguage({
        variables: {
          domainSlug: domain,
          languageName: language,
          timezone: formData.timezone?.value,
        },
      });

      analyticsSDK.landing.sendUserDomainChangedEvent({
        oldDomain: String(data.authUser.domain?.slug),
        newDomain: domain,
        source: 'country_selector',
      });
    }

    changeLanguage({
      domain,
      language,
    });

    if (closeModal) {
      closeModal();
    }
  }, [
    getDomainLanguage,
    data?.authUser,
    closeModal,
    updateDomainAndLanguage,
  ]);

  return (
    <Form
      onSubmit={handleSubmit}
      render={(formRenderProps) => (
        <CountrySelectorForm
          {...formRenderProps}
        />
      )}
    />
  );
};
