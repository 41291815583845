import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '@/lib/classNames';
import { FormField } from '@/components/ui/FormElements/FormField';
import { InputRadio } from '@/components/ui/FormElements/FormInputs/InputRadio';
import { useLanguages } from '@/controllers/i18n/i18n.hooks/useLanguages';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Loader } from '@/components/ui/Loader';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { mainDomainLanguages } from '@/controllers/language/language.constants';
import styles from './CountrySelectorElements.module.scss';

interface Props {
  domain: string;
}

interface Options {
  value: string;
  label: string;
  order: number;
}

export const LanguageSelector:React.FC<Props> = ({ domain }) => {
  const fieldName = 'language';
  const { t } = useTranslation([
    I18N_CODES.common, I18N_CODES.countrySelector,
  ]);

  const { language: userLanguage } = useSubDomainContext();

  const [selectedLanguage, setSelectedLanguage] = useState(userLanguage);

  useEffect(() => {
    setSelectedLanguage(userLanguage);
  }, [userLanguage]);

  const [languages, { loading }] = useLanguages(domain);

  const options = useMemo(() => (
    languages
      .reduce((acc: Options[], language) => {
        if (language.name === 'ru' && userLanguage !== 'ru') {
          return acc;
        }

        const order = mainDomainLanguages[domain] === language.name
          ? 0
          : 1;

        return [
          ...acc,
          {
            value: language.name,
            label: t(`${I18N_CODES.common}:language_${language.name}`),
            order,
          },
        ];
      }, [])
      .sort((languageA, languageB) => languageA.order - languageB.order)
  ), [languages, t, userLanguage, domain]);

  const defaultValue = useMemo(() => {
    const defaultOption = options.find(
      (option) => option.value === userLanguage,
    );

    if (defaultOption) {
      return defaultOption.value;
    }

    return options[0]?.value ?? DEFAULT_LOCALE;
  }, [options, userLanguage]);

  return (
    <div className={styles.languageSelector}>
      {loading
        ? (
          <Loader loading={loading} size={32} />
        )
        : (
          <FormField
            label={{
              for: 'language-selector',
              text: t(`${I18N_CODES.countrySelector}:country_selector_language_input`),
            }}
            renderInput={({ ...inputProps }) => (
              <div className={styles.inputsWrapper}>
                {options.map((option) => (
                  <InputRadio
                    {...inputProps}
                    className={cn(
                      styles.label,
                      {
                        [styles.selected]: selectedLanguage === option.value,
                      },
                      'mb-8',
                    )}
                    onClick={() => {
                      setSelectedLanguage(option.value);
                    }}
                    config={{
                      value: option.value,
                      defaultValue,
                    }}
                    key={option.label}
                    label={option.label}
                    data-qa='language-selector-button'
                  />
                ))}
              </div>
            )}
            name={fieldName}
            disabled={false}
          />
        )}
    </div>
  );
};
